<template>
    <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..."  class="qualityWarn-container common-padding">
        <breadcrumb :BreadcrumbData="BreadcrumbData" class="common-noMarginLeft"></breadcrumb>
        <div class="common-screen-container common-noMarginLeft" style="padding-left: 0;">
            <div class="common-input-container">
                <span>预警编号:</span>
                <el-input class="common-screen-input" v-model="params.code" placeholder="质检记录编号" clearable></el-input>
            </div>
            <div class="common-input-container">
                <span>处理状态:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.dealStatus" placeholder="请选择" clearable filterable>
                    <el-option key="unDeal" label="未处理" value="unDeal"></el-option>
                    <el-option key="deal" label="已处理" value="deal"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>超时原因:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.remarkType" placeholder="请选择" clearable filterable>
                    <el-option key="full" label="咨询量爆量" value="full"></el-option>
                    <el-option key="personal" label="个人原因" value="personal"></el-option>
                    <el-option key="unusual" label="软件原因" value="unusual"></el-option>
                </el-select>
            </div>
            <!-- <div class="common-input-container">
                <span>查看状态:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.readFlag" placeholder="请选择" clearable filterable>
                    <el-option key="0" label="未查看" value="0"></el-option>
                    <el-option key="1" label="已查看" value="1"></el-option>
                </el-select>
            </div> -->
            <div class="common-input-container">
                <span>预警类型:</span>
                <el-select class="wranType" v-model="params.type"  @change="commonFun" placeholder="请选择" clearable filterable>
                    <el-option key="first" label="首响超时" value="first"></el-option>
                    <el-option key="avg" label="平响超时" value="avg"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>超时时长:</span>
                <el-input placeholder="时间" v-model="params.endDuration" class="input-with-select">
                    <el-input placeholder="时间" class="wran-input" slot="prepend" v-model="params.startDuration">
                        <div slot="append">至</div>
                    </el-input>
                </el-input>
            </div>
            <br/>
            <div class="common-input-container">
                <span>店铺名称:</span>
                 <el-input class="common-screen-input" v-model="params.shopName" placeholder="店铺名称" clearable></el-input>
            </div>
            <div class="common-input-container">
                <span>子账号名称:</span>
                 <el-input class="common-screen-input" v-model="params.subaccountName" placeholder="子账号名称" clearable></el-input>
            </div>
            <div class="common-input-container">
                <span>客服名称:</span>
                <el-input class="common-screen-input" v-model="params.adminNames" placeholder="客服名称" clearable></el-input>
            </div>
            <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
                <span>客服所属公司:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.companyId" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in rolesCompany" :key="item.id + ''" :label="item.name" :value="item.id + ''"> </el-option>
                </el-select>
            </div>
            <div class="common-input-container" style="margin-bottom: 0;">
                <span style="margin-right:16px">聊天时间:</span>
                <div class="common-input-container">
                    <common-date @commonGetDate="commonGetDate" :radio="commonRadio" :startDate="commonStartDate"  :endDate="commonEndDate" ref="commonReset"></common-date>
                </div>
                <div class="common-input-container">
                    <el-button class="common-screen-btn" type="primary" @click="() => {commonFun()}">查 询</el-button>
                    <el-button class="common-screen-btn clear" @click="() => {resetBtn()}" plain>重 置</el-button>
                </div>
            </div>
        </div>
        <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => { batchShow() }">批量处理</el-button>
        <el-table class="common-table" @selection-change="handleSelectionChange"  @sort-change="tableSort" :data="tableData">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column width="130px" label="预警编号" fixed="left">
                <template slot-scope="scope">
                    <el-button @click="() => {detailBtn(scope.row)}" type="text" size="small">{{scope.row.code}}</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column width="100px" label="查看状态">
                <template slot-scope="scope">
                    <div class="common-tableStatus-green" v-if="scope.row.remark">已处理</div>
                    <div class="common-tableStatus-blue" v-else-if="scope.row.readFlag == '1'">已查看</div>
                    
                    <div class="common-tableStatus-red" v-else>未查看</div>
                </template>
            </el-table-column> -->
            <el-table-column width="100px" label="处理状态">
                <template slot-scope="scope">
                    <div class="common-tableStatus-blue" v-if="scope.row.dealStatus == 'deal'">已处理</div>
                    <div class="common-tableStatus-red" v-if="scope.row.dealStatus == 'unDeal'">未处理</div>
                </template>
            </el-table-column>
            <el-table-column width="120px" label="超时原因">
                <template slot-scope="scope">
                    <div class="common-tableStatus-blue" v-if="scope.row.remarkType == 'full'">咨询量爆量</div>
                    <div class="common-tableStatus-blue" v-if="scope.row.remarkType == 'personal'">个人原因</div>
                    <div class="common-tableStatus-blue" v-if="scope.row.remarkType == 'unusual'">软件原因</div>
                </template>
            </el-table-column>
            <el-table-column width="100px" label="预警类型">
                <template slot-scope="scope">
                    <div v-if="scope.row.warningType == 'first'">首响超时</div>
                    <div v-if="scope.row.warningType == 'avg'">平响超时</div>
                </template>
            </el-table-column>
            <el-table-column width="140px" :formatter="tableColumn" sortable="custom" prop="responseTime" label="超时时长（s）"></el-table-column>
            <el-table-column width="160px" :formatter="tableColumn" prop="shopName" label="店铺名称"></el-table-column>
            <el-table-column width="180px" :formatter="tableColumn" prop="subaccountName" label="子账号名称"></el-table-column>
            <el-table-column width="200px" :formatter="tableColumn" prop="adminNames" label="负责客服"></el-table-column>
            <el-table-column width="160px" :formatter="tableColumn" prop="companyName" label="客服所属公司"></el-table-column>
            <el-table-column width="200px" :formatter="tableColumn" prop="customerNick" label="顾客账号"></el-table-column>
            <el-table-column width="180px" :formatter="tableColumn" prop="recordTime" label="聊天时间"></el-table-column>
            <el-table-column width="160px" :formatter="tableColumn" prop="remarkName" label="备注最后填写人"></el-table-column>
            <el-table-column width="130px" :formatter="tableColumn" prop="remark" show-overflow-tooltip label="备注"></el-table-column>
        </el-table>
        <el-pagination
            style="text-align: center"
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e, 'page')"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total"
            >
        </el-pagination>
        <!-- 详情 -->
        <quality-warn-detail ref="warnDetail" @loadDetailInfo="loadDetailInfo" :drawer="drawer" :handleClose="() => { handleClose() }"></quality-warn-detail>

        <el-dialog  v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="超时处理" :append-to-body="true" width="530px" :visible.sync="batchVisible" :close-on-click-modal="false">
            <el-form :model="ruleFormBatch" :rules="ruleBatchRules" ref="ruleFormBatch" label-width="110px" class="demo-ruleForm">
                <el-form-item label="原因类型：" prop="remarkType">
                    <el-select style="width: 100%" v-model="ruleFormBatch.remarkType" placeholder="请选择原因类型" filterable>
                        <el-option label="咨询量爆量" value="full"></el-option>
                        <el-option label="个人原因" value="personal"></el-option>
                        <el-option label="软件原因" value="unusual"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="超时说明：" prop="remark">
                    <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormBatch.remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="batchVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { warnBatchRemark() }">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import CommonDate from "../../components/common/commonDate.vue"
import { tableColumn, Config, DateTransform } from "../../utils/index.js"
import QualityWarnDetail from "./children/qualityWarnDetail.vue"
import { qualityWarn, getListByParentId, warnBatchRemark } from "../../service/service.js"
import { commonDictSys } from "../../service/common.js"
import { getPlatList } from "../../service/customer.js"
export default {
    components: {
        Breadcrumb,
        QualityWarnDetail,
        CommonDate,
    },
    data() {
        return {
            input3: '',
            tableColumn,
            rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
            drawer: false,
            loading: false,
            BreadcrumbData: [
                { title: "首页", isLink: true, url: "/index" },
                { title: "质检", isLink: false },
                { title: "质检预警", isLink: false },
            ],
            tableData: [],
            tableRadio: '今天',
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            params: {
                page: 1,
                pageSize: 10
            },
            commonRadio: this.$route.query.startDate ? null : 1,
            commonStartDate: this.$route.query.startDate ? DateTransform(this.$route.query.startDate) : DateTransform(new Date),
            commonEndDate: this.$route.query.endDate ? DateTransform(this.$route.query.endDate) : DateTransform(new Date),
            platId: [],
            quality1: [],
            quality2: [],
            quality3: [],
            brand1: [],
            brand2: [],
            brand3: [],
            DictSysAll: {'7087cd552e9b4696bca2fcbb6261caae': []},
            platList: [], // 所属平台/类目下拉
            ruleFormBatch: { // 批量备注
                remarkType: '',
                remark: ''
            },
            ruleBatchRules: {
                remarkType: [{ required: true, message: "不能为空", trigger: "change" }],
                remark: [{ required: true, message: "不能为空", trigger: "change" }],
            },
            batchVisible: false,
            multipleTable: [], // 选中的值
        }
    },
    created() {
        if (this.$route.query.companyId) {
            this.params.companyId = this.$route.query.companyId;
        } else if (this.rolesCompany && this.rolesCompany.length) {
            this.params.companyId = this.rolesCompany[0].id + '';
        }
         if (this.$route.query.dealStatus) {
            this.params.dealStatus = this.$route.query.dealStatus;
        }
        if (this.$route.query.startDuration) {
            this.params.startDuration = this.$route.query.startDuration;
        }
        if (this.commonStartDate && this.commonEndDate) {
            this.params.startDate = this.commonStartDate;
            this.params.endDate = this.commonEndDate;
        }
        this.getPlatList()
        this.getListByParentId("quality", 0, "quality1");
        this.getListByParentId("brand", 0, "brand1");
        this.qualityWarn()
        this.commonDictSys('7087cd552e9b4696bca2fcbb6261caae')
        // this.commonGetDate(DateTransform(new Date), DateTransform(new Date))
    },
    methods: {
        tableSort(e) { // 表格项排序
            let params = { ...this.params };
            // params.sortField = e.prop; // 排序字段名
            switch (e.order) {
                case "ascending":
                params.durationSort = 1;
                break;
                case "descending":
                params.durationSort = 0;
                break;
                default:
                params.durationSort = null;
            }
            this.params = params;
            this.qualityWarn()
        },
        batchShow() { // 批量备注弹窗
            let multipleTable = [...this.multipleTable];
            if (!multipleTable || !multipleTable.length) {
                this.$message.error("请先选择");
                return false;
            }
            let newArr = [];
            multipleTable.map((item) => {
                newArr.push(item.id);
            });
            this.ruleFormBatch.ids = newArr.join(",")
            this.batchVisible = true;
        },
        async warnBatchRemark() { // 批量设置备注
            this.$refs['ruleFormBatch'].validate(async (valid) => {
                if (valid) { // 发送请求
                let ruleFormBatch = {...this.ruleFormBatch}
                this.loading = true
                let res = await warnBatchRemark(ruleFormBatch)
                this.loading = false
                if (res.code == 200) {
                    this.$message.success("处理成功")
                    this.qualityWarn()
                    this.batchVisible = false
                }
                } else {
                return false
                }
            });
        },
        handleSelectionChange(row) {
            this.multipleTable = row;
        },
        handleClose () { // 关闭弹窗
            this.qualityWarn()
            this.drawer = false
        },
        async getPlatList() { // 所属平台/类目下拉
            let resData = (await getPlatList({ pageNum: -1 })).data;
            this.platList = resData;
        },
        dictFun(value, type, key) { // 问题筛选-重置下级菜单
            switch (key) {
                case "quality2":
                this.params.classificationQualityPointId = "";
                this.params.classificationQualityDescId = "";
                break;
                case "quality3":
                this.params.classificationQualityDescId = "";
                break;
                case "brand2":
                this.params.classificationBrandPointId = "";
                this.params.classificationBrandDescId = "";
                break;
                case "brand3":
                this.params.classificationBrandDescId = "";
                break;
            }
            console.log('-----------------------')
            this.getListByParentId(type, value, key);
            this.commonFun();
        },
        async getListByParentId(type, parentId, key) { // 问题下拉
            if (parentId || parentId == 0) {
                let resData = (await getListByParentId({ type, parentId })).data;
                this[key] = resData || [];
            }
        },
        commonFun() { // 下拉筛选
            this.params.page = 1;
            this.params.pageSize = 10;
            this.qualityWarn()
        },
        async commonDictSys (uuid) { // 获取字典值接口
            let { data } = await commonDictSys({uuid})
            this.DictSysAll[uuid] = data.list || []
        },
        async qualityWarn () { // 质检记录列表
            let platId = [...this.platId];
            let params = {...this.params}
            if (platId && platId.length) {
                params.platformId = platId[0];
                if (platId[1]) {
                    params.categoryId = platId[1];
                }
            }
            if (!params.durationSort && params.durationSort != 0) {
                delete params.durationSort
            }
            this.loading = true
            let { data } = await qualityWarn(params)
            this.loading = false
            this.pagination.total = data.total;
            this.tableData = data.list;
        },
        detailBtn (row) { // 查看详情按钮
            this.$refs.warnDetail.tableParams(row)
            this.drawer = true
        },
        async loadDetailInfo(id) {
            let { data } = await qualityWarn({id})
            this.$refs.warnDetail.tableParams(data.list[0])
        },
        resetBtn() {// 重置
            this.$refs.commonReset.resetFun(this.commonRadio, this.commonStartDate, this.commonEndDate)
            this.params = {
                page: 1,
                pageSize: 10,
                startDate: this.commonStartDate,
                endDate: this.commonEndDate,
            };
            if (this.rolesCompany && this.rolesCompany.length) {
                this.params.companyId = this.rolesCompany[0].id;
            }
            this.qualityWarn()
        },
        commonGetDate (startDate, endDate) { // 时间筛选
            this.params.startDate = startDate
            this.params.endDate = endDate
            this.qualityWarn()
        },
        pageFun(e, type) { // 分页
            this.params[type] = e;
            this.qualityWarn();
        },
    }
}
</script>
<style lang="less" scoped>
.qualityWarn-container {
    text-align: left;
    .input-with-select {
        width: 180px;
        margin-left: 16px;
    }
    /deep/.input-with-select .el-input-group__prepend {
        background-color: #fff;
        padding: 0;
    }
    /deep/.input-with-select .el-input__inner{
       border-left: none;
       padding-right: 0;
       &:focus {
           border-color: #DCDFE6;
       }
    }
    .wran-input {
        width: 100px;
        border: none;
    }
    /deep/.wran-input .el-input-group__append {
        background-color: #fff;
        padding: 0;
        border: none;
        width: 20px;
    }
    /deep/.wran-input .el-input__inner{
        border: none;
        line-height: 30px;
        height: 30px;
        padding-right: 0;
        border-radius: 6px;
    }
    .wranType {
        width: 120px;
        margin-right: 10px;
        margin-left: 16px;
    }
}
</style>